<template>
  <div style="height: 100%">
    <BooksFilters />
    <v-progress-linear v-if="isLargeLoading" indeterminate></v-progress-linear>
    <v-container v-if="noRecordsFound" class="text-center py-10">
      No books found.
    </v-container>
    <div class="books-listing-parent" ref="scrollable" v-bar>
      <div>
        <v-container
          :class="[isEmpty ? 'd-none' : '', !loadedAll ? 'pull-up-margin' : '']"
        >
          <v-row>
            <BookCard
              v-for="book in data"
              :key="book._id"
              :book="book"
              sm="6"
              xs="12"
              md="4"
              lg="4"
              cols="12"
            />
          </v-row>
        </v-container>
        <SkeltonLoader v-if="!loadedAll" />
      </div>
    </div>
    <BookQrCode v-model="showQrCode" :bookId="qrCodeBook" />
  </div>
</template>

<script>
import { useBooks } from "@/modules";
import BookCard from "@/components/BookCard";
import SkeltonLoader from "@/components/SkeltonLoader";
import BooksFilters from "@/components/BooksFilters";
import BookQrCode from "@/components/BookQrCode";

export default {
  setup: useBooks,
  components: {
    BookCard,
    SkeltonLoader,
    BooksFilters,
    BookQrCode
  }
};
</script>
