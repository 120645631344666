<template>
  <v-col :sm="sm" :xs="xs" :md="md" :lg="lg" :cols="cols">
    <v-tooltip top max-width="250" nudge-top="-15" transition="fade-transition" open-delay="500">
      <template v-slot:activator="{ on, attrs }">
        <v-card tile v-bind="attrs" v-on="on" class="book-card">
          <v-card-text>
            <div>
              <v-checkbox v-if="type" v-model="selectedBooks" :value="book._id" dense :disabled="returnPrevented ||
                returnRestricted ||
                (type === 'return' && book.status === 2 && fromSubLibrary)
                " hide-details class="ma-0 pa-0" :label="`#${book.bookNumber}`"></v-checkbox>
              <span v-else>#{{ book.bookNumber }}</span>
              <router-link v-if="!type" :to="`/books/${book._id}`">
                <v-icon class="float-right pointer" size="20" color="primary">mdi-dots-vertical</v-icon>
              </router-link>
              <v-icon v-if="!type" @click="showQrCode(book._id)" class="float-right pointer mr-1" size="20"
                color="primary">mdi-qrcode</v-icon>
            </div>
            <div class="text--primary force-single-line">
              {{ book.name }}
            </div>
            <div :class="['force-single-line', book.author ? '' : 'not-assigned']">
              {{ book.author ? book.author : "No author assigned" }}
            </div>
            <div class="force-single-line">{{ book.type }}</div>
            <div class="force-single-line">
              Shelf number
              {{ book.shelfNumber ? `S${book.shelfNumber}` : "not assigned" }}
            </div>
            <div v-if="!type">
              Purchased on {{ new Date(book.dateAdded) | formatDate }}
            </div>
            <div v-else-if="type === 'return'" class="force-single-line">
              Distributed on
              {{
                new Date(
                  fromSubLibrary
                    ? book.slastDistributedDate
                    : book.lastDistributedDate
                ) | formatDateUtcFix
              }}
              {{
                book.lastDistributedBy && ` by ${book.lastDistributedBy.name}`
              }}
              <span v-if="returnPrevented || returnRestricted" class="orange--text">(Return not allowed)</span>
            </div>
            <div v-else>
              <span v-if="book.status === 2">Distributed on
                {{ new Date(book.lastDistributedDate) | formatDate }}</span>
              <span v-else-if="book.lastDistributedDate">Last distributed on
                {{ new Date(book.lastDistributedDate) | formatDate }}</span>
              <span v-else>Not yet distributed</span>
            </div>

            <v-badge dot inline left tile :color="statusColors[book.status]" class="stock-badge">
              <div :class="[
                'force-single-line',
                `${statusColors[book.status]}--text`
              ]">
                <span v-if="book.status === 1">In stock {{ book.subLibrary && !isSubLibrary ? '(Sub Library)' : ''
                }}</span>
                <span v-else-if="book.status === 2">
                  {{
                    book.distributedTo
                    ? `# ${book.distributedTo.memberNumber} ${book.distributedTo.name} ${book.subLibrary && !isSubLibrary ?
                      '(Sub Library)' :
                      ''}`
                    : "Out of stock"
                  }}
                </span>
                <span v-else>Removed</span>
              </div>
            </v-badge>
          </v-card-text>
        </v-card>
      </template>
      # {{ book.bookNumber }}, {{ book.name }}
      <span v-if="returnRestricted" class="orange--text">
        <br />
        You are not allowed to return book distributed by other users
      </span>
      <span v-else-if="returnPrevented" class="orange--text">
        <br />
        Return allowed to only admin and
        {{ book.lastDistributedBy.name }}
      </span>
    </v-tooltip>
  </v-col>
</template>

<script>
import { computed, toRefs } from "@vue/composition-api";
import store from "@/store";

const showQrCode = (bookId) => {
  if (store.mainLayout.isFreeTrail) {
    store.app.showDialog(
      "Not allowed",
      "This account is under free trail, upgrade your account to do this action.",
      "error"
    );
  } else if (store.mainLayout.isExpired) {
    store.app.showDialog(
      "Not allowed",
      `This account has been expired, ${store.mainLayout.isFreeTrail ? "upgrade" : "renew"
      } your account to do this action.`,
      "error"
    );
  } else {
    store.books.state.showQrCode = true;
    store.books.state.qrCodeBook = bookId;
  }
};

export default {
  props: ["lg", "md", "sm", "xs", "cols", "book", "type", "fromSubLibrary"],
  setup(props) {
    const isSubLibrary = computed(() =>
      store.mainLayout?.state?.libraryParent ? true : false
    );
    const returnPrevented = computed(() => {
      const user = store.mainLayout.state.user;
      return (
        user.role !== "admin" &&
        props.type === "return" &&
        props.book.lastDistributedBy &&
        props.book.lastDistributedBy._id !== user._id &&
        props.book.lastDistributedBy.mustReturnByMe
      );
    });
    const returnRestricted = computed(() => {
      const user = store.mainLayout.state.user;
      return (
        props.type === "return" &&
        user.returnRestricted &&
        props.book.lastDistributedBy &&
        user._id != props.book.lastDistributedBy._id
      );
    });
    let data = {
      statusColors: { 1: "green", 2: "orange", 3: "grey" }
    };
    if (props.type === "distribute") {
      data = {
        ...data,
        ...toRefs(store.distribute.state)
      };
    } else if (props.type === "return") {
      data = {
        ...data,
        ...toRefs(store.returnBook.state)
      };
    }
    return { ...data, showQrCode, returnPrevented, returnRestricted, isSubLibrary };
  }
};
</script>

<style lang="scss" scoped>
.book-card::after {
  background-image: url("~@/assets/icons/book-bk-icon.svg");
}
</style>
