<template>
  <div>
    <v-container>
      <v-row>
        <v-col
          sm="6"
          xs="12"
          md="4"
          lg="4"
          cols="12"
          v-for="i in counts[getScreenSize]"
          :key="i"
        >
          <v-card tile>
            <v-card-text>
              <v-skeleton-loader
                :height="cardHeight - offset"
                tile
                :type="loaderType"
              ></v-skeleton-loader>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import { toRefs } from "@vue/composition-api";
import store from "@/store";
export default {
  props: {
    cardHeight: {
      type: String,
      default: "186"
    },
    loaderType: {
      type: String,
      default: "table-cell,paragraph,text,text"
    }
  },
  setup() {
    const offset = 34;
    return {
      ...toRefs(store.app),
      offset,
      counts: {
        sm: 4,
        xs: 2,
        md: 6,
        lg: 6,
        xl: 6
      }
    };
  }
};
</script>
