<template>
  <v-dialog max-width="250" :value="value" @input="$emit('input', $event)">
    <template v-slot:default="dialog">
      <v-card>
        <v-card-text class="pt-6 text-center">
          <v-skeleton-loader
            v-if="isLoading"
            width="150"
            height="150"
            tile
            class="mx-auto"
            type="image"
          ></v-skeleton-loader>
          <img v-else class="qr-code-wrapper" :src="svgData" />
        </v-card-text>
        <v-card-actions class="justify-end">
          <v-btn text @click="download">Save</v-btn>
          <v-btn text @click="dialog.value = false">Close</v-btn>
        </v-card-actions>
      </v-card>
    </template>
  </v-dialog>
</template>

<script>
import { reactive, toRefs, watch } from "@vue/composition-api";
import axios from "axios";
import helpers from "@/helpers";

const state = reactive({
  svgData: "",
  isLoading: true
});

const loadQrCode = (bookId) => {
  state.isLoading = true;
  axios
    .get(`${process.env.VUE_APP_API}/book/${bookId}/qrCode`)
    .then((response) => {
      state.isLoading = false;
      state.svgData = response.data;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
};

export default {
  props: {
    value: {
      type: Boolean
    },
    bookId: {
      type: String
    }
  },
  setup(props) {
    const download = () => {
      helpers.downloadFromDataUri(state.svgData);
    };
    watch(
      () => props.bookId,
      (value) => {
        loadQrCode(value);
      }
    );
    return {
      ...toRefs(state),
      download
    };
  }
};
</script>
