<template>
  <div>
    <v-btn
      v-if="!isReportFilter"
      @click="toggleFilterOnXs()"
      class="search-bar-toggle d-md-none"
    >
      <v-icon>mdi-dots-horizontal</v-icon>
    </v-btn>
    <v-dialog v-model="showQrModal" width="600">
      <template v-slot:default="dialog">
        <v-card>
          <v-toolbar color="primary" dark class="mb-4">
            <span>Generate QR codes</span>
          </v-toolbar>

          <v-card-text v-if="qrInfo.isGenerating">
            You cannot generate zip file as already a zip file generation is in
            progress, please
            <a @click.prevent="getQRcodeCodeZipInfo()"> try again </a>
            after some time.
          </v-card-text>
          <v-card-text v-else-if="!qrInfo.s3FileHeadInfo">
            Previously generated zip file not found.
          </v-card-text>
          <v-card-text v-else>
            <span v-if="isQrRecent"
              >Your recently generated QR codes zip file at
              {{ qrInfo.s3FileHeadInfo.LastModified | formatTime }} is ready
              <a :href="qrInfo.s3FileHeadInfo.url">click here</a>
              to download.
            </span>
            <span v-else>
              Previously generated QR codes zip file was on
              {{ qrInfo.s3FileHeadInfo.LastModified | formatDateTime }},
              <a :href="qrInfo.s3FileHeadInfo.url">click here</a>
              to download.
            </span>
          </v-card-text>

          <v-divider></v-divider>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" text @click="dialog.value = false">
              Cancel
            </v-btn>
            <v-btn
              v-if="!qrInfo.isGenerating"
              tile
              class="primary"
              text
              @click="generateQRcodeCodeZipInfo()"
            >
              Generate
            </v-btn>
          </v-card-actions>
        </v-card>
      </template>
    </v-dialog>
    <v-sheet
      :class="[
        !isReportFilter && 'books-filters-parent',
        !isReportFilter && showFilterOnXs && 'show',
        isReportFilter && 'pt-10 in-report-filter'
      ]"
      outlined
    >
      <div v-if="!isReportFilter" :class="['px-6 pt-2']" style="height: 50px">
        <v-btn
          tile
          x-small
          @click="toggleFilterOnXs()"
          class="float-right ml-2 d-md-none mt-1"
          >Hide</v-btn
        >
        <v-btn tile x-small @click="resetFilter()" class="float-right ml-2 mt-1"
          >Reset</v-btn
        >
        <v-btn
          v-if="!isSubLibrary"
          tile
          x-small
          @click="addNewBook()"
          class="primary float-right ml-2 mt-1"
          >Add new book</v-btn
        >
        <v-btn
          tile
          x-small
          @click="getQRcodeCodeZipInfo()"
          class="primary float-right mt-1"
          >Generate QR codes as zip file</v-btn
        >
      </div>
      <div
        :class="['d-flex', 'align-center', 'fill-width']"
        style="height: calc(100% - 50px)"
      >
        <v-row class="mx-0 px-4">
          <v-col
            v-if="!isReportFilter"
            xl="2"
            md="2"
            sm="12"
            cols="12"
            class="px-0 py-0 my-0"
          >
            <v-text-field
              v-model="searchKey"
              clearable
              dense
              class="mx-2"
              append-icon="mdi-magnify"
              label="Search"
            >
            </v-text-field>
          </v-col>

          <v-col
            v-if="!isReportFilter"
            xl="2"
            md="2"
            sm="12"
            cols="12"
            class="px-0 py-0 my-0"
          >
            <v-select
              dense
              class="mx-2"
              :items="searchByFilters"
              item-text="name"
              item-value="_id"
              v-model="searchBy"
              label="Search By"
              clearable
            ></v-select>
          </v-col>

          <v-col
            :xl="isReportFilter ? 3 : 2"
            :md="isReportFilter ? 3 : 2"
            sm="12"
            cols="12"
            class="px-0 py-0 my-0"
          >
            <v-select
              dense
              class="mx-2"
              v-model="stock"
              :items="searchByStock"
              item-text="name"
              item-value="_id"
              label="Stock"
              clearable
            >
              <template v-slot:selection="{ item }">
                <v-badge dot inline tile :color="statusColors[item._id]">
                  {{ item.name }}
                </v-badge>
              </template>
              <template v-slot:item="{ item }">
                <v-badge dot inline tile :color="statusColors[item._id]">
                  {{ item.name }}
                </v-badge>
              </template>
            </v-select>
          </v-col>

          <v-col
            :xl="isReportFilter ? 3 : 2"
            :md="isReportFilter ? 3 : 2"
            sm="12"
            cols="12"
            class="px-0 py-0 my-0"
          >
            <vc-data-autocomplete
              v-model="type"
              api="/book/type"
              map-value="name"
              emit-on-search
              clearable
              class="mx-2"
              dense
              label="Category"
            />
          </v-col>

          <v-col
            :xl="isReportFilter ? 3 : 2"
            :md="isReportFilter ? 3 : 2"
            sm="12"
            cols="12"
            class="px-0 py-0 my-0"
          >
            <v-select
              dense
              class="mx-2"
              v-model="orderBy"
              :items="searchByOrder"
              item-text="name"
              item-value="orderBy"
              label="Order by"
            ></v-select>
          </v-col>

          <v-col
            :xl="isReportFilter ? 3 : 2"
            :md="isReportFilter ? 3 : 2"
            sm="12"
            cols="12"
            class="px-0 py-0 my-0"
          >
            <v-menu
              ref="menu"
              v-model="menu"
              :close-on-content-click="false"
              :return-value.sync="purchaseDates"
              transition="scale-transition"
              offset-y
              min-width="290px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  hint="Select a date range"
                  dense
                  class="mx-2"
                  clearable
                  v-model="purchaseDateRangeText"
                  label="Date added"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                  :append-icon="
                    purchaseDateRangeText && purchaseDateRangeText.includes('~')
                      ? ''
                      : 'mdi-calendar-range'
                  "
                ></v-text-field>
              </template>
              <v-date-picker
                :max="purchaseFilterMaxDate"
                no-title
                v-model="purchaseDates"
                :reactive="true"
                range
                scrollable
              >
                <v-spacer></v-spacer>
                <v-btn text color="primary" @click="menu = false">
                  Cancel
                </v-btn>
                <v-btn
                  text
                  color="primary"
                  @click="$refs.menu.save(purchaseDates)"
                >
                  OK
                </v-btn>
              </v-date-picker>
            </v-menu>
          </v-col>
        </v-row>
      </div>
    </v-sheet>
  </div>
</template>

<script>
import store from "@/store";
import { reactive, toRefs, watch, computed } from "@vue/composition-api";
import axios from "axios";
import moment from "moment";

const state = reactive({
  showQrModal: false,
  isQrRecent: false,
  qrInfo: null
});

watch(
  () => state.qrInfo,
  (value) => {
    if (value) {
      state.isQrRecent =
        value?.s3FileHeadInfo?.LastModified &&
        moment(store.app.getTime).diff(
          value?.s3FileHeadInfo?.LastModified,
          "minutes"
        ) <= 15;
    }
  }
);

const getQRcodeCodeZipInfo = (hideLoader) => {
  !hideLoader && store.app.showLoader("Please wait...");
  axios
    .get(`${process.env.VUE_APP_API}/qrCodesZip`)
    .then((response) => {
      state.qrInfo = response.data;
      !hideLoader && (state.showQrModal = true);
    })
    .finally(() => !hideLoader && store.app.hideLoader());
};

const generateQRcodeCodeZipInfo = () => {
  store.app.showLoader("Generating zip file...");
  axios
    .post(`${process.env.VUE_APP_API}/qrCodesZip`, null, {
      timeout: 25000
    })
    .then((response) => {
      getQRcodeCodeZipInfo(true);
      window.location.href = response.data.url;
    })
    .catch((error) => {
      getQRcodeCodeZipInfo(true);
      if (error.message === "timeout of 25000ms exceeded") {
        store.app.showDialog(
          "Notice!",
          "This may take some time depending upon the number of books in library, please check after few minutes",
          "warning"
        );
      } else {
        if (error?.response?.data?.message) {
          store.app.showAlert(error?.response?.data?.message);
        } else {
          store.app.showAlert("Some error occured!");
        }
      }
    })
    .finally(() => store.app.hideLoader());
};

export default {
  props: ["isReportFilter"],
  setup(props, { root }) {
    const isSubLibrary = computed(() =>
      store.mainLayout?.state?.libraryParent ? true : false
    );
    return {
      ...(props.isReportFilter !== true
        ? toRefs(store.books.filters)
        : toRefs(store.booksReport.filters)),
      ...toRefs(state),
      isSubLibrary,
      menu: false,
      getQRcodeCodeZipInfo,
      generateQRcodeCodeZipInfo,
      statusColors: { 1: "green", 2: "orange", 3: "grey" },
      addNewBook: () => {
        root._router.push({ name: "new_book" });
      }
    };
  }
};
</script>
